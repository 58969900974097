<template>
    <div class="wrapper-number">
        <div class="wrapper-number-yeekee">
            <div v-for="(data, i) of list" :key="data.id" class="box-number-ranking"
                    :class="{'box-number-ranking-dark': isWinner(i)}">
                <div class="box-number-yeekee-left">
                    <div class="box-ranking">อันดับที่ {{ranking(i)}}</div>
                    <h3 style="display: unset;" :class="{'text-dark': !isWinner(i)}">{{data.number | fillZero}}</h3>
                </div>

                <div class="box-number-yeekee">
                    <div class="detail-number-yeekee">
                        <div><span :class="{'text-dark': !isWinner(i)}">ผู้ส่งเลข :</span>
                            {{data.user_name | strCensored}}
                        </div>
                        <div><span :class="{'text-dark': !isWinner(i)}">เมื่อ :</span>
                            {{data.created_at | dateTimeFormat}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import AppMixin from '../../mixins/App'

export default {
    name: 'YeekeeGuessList',
    mixins: [
        AppMixin,
    ],
    props: [
        'list',
    ],
    methods: {
        isWinner(i) {
            return i === 0 || i === 15
        },
        ranking(i) {
            return parseInt(i) + 1
        },
    }
}
</script>