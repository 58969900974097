<template>
    <div class="card card-last-update">
        <div class="card-header">
            <div  class="logo-yeekee"></div>
            <div v-if="round" class="card-header-detail">
                <div v-if="round" class="title-card-header-detail">
                    <ul>
                        <li>
                            <h4>{{"yeekee" | lottoTypeDisplay}}</h4>
                            <p>{{(round.sub_round) ? 'รอบที่ ' + round.sub_round : ''}}</p>
                        </li>
                        <li>
                            <span>ประจำงวด : {{round.date}}</span>
                        </li>
                    </ul>
                </div>

                <span class="box-coundown-live">
                    <Alarm @timeout="$emit('timeout')" 
                    :start="round.timeout" 
                    :extra_minutes="2" 
                    display_mode="guess"
                    :show_on_timeout="true"/>
                </span>
            </div>
        </div>

        <div class="content-live">
            <div class="content-live-row">
                <div class="content-live-row-left">
                    <ul class="">
                        <!-- <li class="digit">
                            <div class="flip-card-inner">
                                <div class="flip-card-front">
                                    <span>-</span>
                                </div>
                                <div class="flip-card-back">
                                    <span>0</span>
                                </div>
                            </div>
                        </li> -->
                        <li class="digit">
                            <transition name="flip" mode="out-in">
                                <div class="flip-card-inner" :key="getResultSamTuaBonDigit(0)">
                                    <div class="flip-card-front">
                                        <span>{{getResultSamTuaBonDigit(0)}}</span>
                                    </div>
                                </div>
                            </transition>
                        </li>
                        <li class="digit">
                            <transition name="flip" mode="out-in">
                                <div class="flip-card-inner" :key="getResultSamTuaBonDigit(1)">
                                    <div class="flip-card-front">
                                        <span>{{getResultSamTuaBonDigit(1)}}</span>
                                    </div>
                                </div>
                            </transition>
                        </li>
                        <li class="digit">
                            <transition name="flip" mode="out-in">
                                <div class="flip-card-inner" :key="getResultSamTuaBonDigit(2)">
                                    <div class="flip-card-front">
                                        <span>{{getResultSamTuaBonDigit(2)}}</span>
                                    </div>
                                </div>
                            </transition>
                        </li>
                    </ul>
                    <label>3 ตัวบน</label>
                </div>

                <div class="content-live-row-right">
                    <ul class="">
                        <li class="digit">
                            <transition name="flip" mode="out-in">
                                <div class="flip-card-inner" :key="getResultSongTuaLangDigit(0)">
                                    <div class="flip-card-front">
                                        <span>{{getResultSongTuaLangDigit(0)}}</span>
                                    </div>
                                </div>
                            </transition>
                        </li>
                        <li class="digit">
                            <transition name="flip" mode="out-in">
                                <div class="flip-card-inner" :key="getResultSongTuaLangDigit(1)">
                                    <div class="flip-card-front">
                                        <span>{{getResultSongTuaLangDigit(1)}}</span>
                                    </div>
                                </div>
                            </transition>
                        </li>
                    </ul>
                    <label>2 ตัวล่าง</label>
                </div>

            </div>
            <div class="content-live-row-all">
                <ul>
                    <li class="box-live"><span>{{resultText}}</span></li>
                    <li class="box-live box-live-red"><span>{{getResultSongTuaLangDigit(0)}}</span></li>
                    <li class="box-live box-live-red"><span>{{getResultSongTuaLangDigit(1)}}</span></li>
                    <li class="box-live box-live-blue"><span>{{getResultSamTuaBonDigit(0)}}</span></li>
                    <li class="box-live box-live-blue"><span>{{getResultSamTuaBonDigit(1)}}</span></li>
                    <li class="box-live box-live-blue"><span>{{getResultSamTuaBonDigit(2)}}</span></li>
                </ul>
                <label>เลขที่ได้จากการคำนวน</label>
            </div>
        </div>
    </div>
</template>
<script>

import Alarm from '../Alarm'
import AppMixin from '../../mixins/App'

export default {
    name: 'YeekeeLiveResult',
    mixins: [
        AppMixin,
    ],
    components: {
        Alarm,
    },
    props: [
        'round',
        'guessList',
        'guessTotal',
    ],
    computed: {
        result() {
            if(this.guessList.length > 0 && this.guessTotal) {
                return `${parseInt(this.guessTotal) - parseInt(this.guessList[15].number)}`
            } else {
                return ''
            }
        },
        resultText() {
            if(this.result) {
                return this.result.substring(0, this.result.length - 5)
            } else {
                return ''
            }
        },
        resultSongTuaLang() {
            if(this.result) {
                return this.result.substring(this.result.length - 5, this.result.length - 3)
            } else {
                return ''
            }
        },
        resultSamTuaBon() {
            if(this.result) {
                return this.result.substring(this.result.length - 3, this.result.length)
            } else {
                return ''
            }
        },
    },
    methods: {
        getResultSongTuaLangDigit(digit) {
            if(this.resultSongTuaLang) {
                return this.resultSongTuaLang.substring(digit, digit + 1)
            } else {
                return '-'
            }
        },
        getResultSamTuaBonDigit(digit) {
            if(this.resultSamTuaBon) {
                return this.resultSamTuaBon.substring(digit, digit+1)
            } else {
                return '-'
            }
        },
    },
}
</script>
<style scoped>
.flip-enter-active, .flip-leave-active {
    transition: all .1s;
}
.flip-enter, .flip-leave-to {
    transform: rotateX(180deg);
    /* opacity: 0; */
}
.digit:hover .flip-card-inner {
    transform: unset;
}
</style>