<template>

<!--    <div class="ViewResult ViewResultLotto">-->
    <div class="wrapContentIndex wrapContentIndexLotto wrapContentIndexLottoBet wrapContentIndexLottoResult">
        <div class="wrapContentIndexTitle">
            <button style="padding: 0 10px;" class="btn " @click="goHome()"><i class="fas fa-chevron-left"></i></button>
            <h3>ผลการออกรางวัล</h3>
        </div>
        <div class="navTopDesktop">
            <!-- <MenuNavTop  @showMenuNav="showMenuNav"  @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal" ></MenuNavTop> -->
        </div>
        <!-- <FloatButtomRightPanel  :callChatWithAdmin="showChatWithAdminNumber"/> -->
        <MenuBar :callMenu="menuNumber" :callDWMenu="showDepositWithdrawNumber"></MenuBar>
        <div class="wrapContentIndexInfo">
            <div class="contentFlexIndex">
                <div class="contentFlexIndexLeft active">
                    <MenuLeft></MenuLeft>
                </div>
                <div class="contentFlexIndexRight">
                    <!--                    <div class="contentFlexIndexRightInfo">-->
                    <div class="container">
                        <div class="containerGameList">
                            <div class="containerGameListinfo" >
                                <Result></Result>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>

import Result from '@/components/lotto/Result'
import MenuBar from '@/components/MenuBar'
// import FloatButtomRightPanel from '@/components/FloatButtomRightPanel'
// import MenuNavTop from '@/components/MenuNavTop'
import MenuLeft from '@/components/MenuLeft'

export default {
    name: 'ViewResult',
    components: {
        // FloatButtomRightPanel,
        Result,
        MenuBar,
        MenuLeft,
        // MenuNavTop,
    },
    data(){
        return {
            menuNumber :0,
        }

    },
    methods: {
        showMenuNav(menuNumber){
                this.menuNumber = menuNumber;
                let self =this;
                setTimeout(()=>{
                    self.menuNumber=0;
                },1000);
        },
        goHome() {
            this.$router.push({name: 'IndexLotto'})
        },
    }
}
</script>
<style>
    /*.ViewResultLotto .MenuBar.box-all-games{display: none!important}*/
    .ViewResultLotto .box-menu-top-info {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    .ViewResultLotto .wrap-menu-top{z-index: 1}
    .ViewResultLotto span.box-txt-back{display: none}

    @media only screen
    and (max-device-width: 1300px)
    and (orientation: landscape)
    {
        /*.ViewResultLotto .MenuBar.box-all-games{display: block!important}*/
    }
    @media only screen
    and (min-device-width: 1300px)
    and (orientation: landscape)
    {

    }

</style>
<style scoped>
    .ViewResultLotto{
        background-image: linear-gradient(
                135deg
                , #F372D4 10%, #c765f0 100%);
    }
    .ViewResultLotto .wrap-menu-top{
        padding: 10px 5px 10px 5px;
        background-image: none;
    }
</style>