<template>
    <div class="PageResult container container-fluid">
        <div class="nav-top hide">
            <div class="col-nav">
                <a @click="goHome" href="#">
                    <button class="btn ">
                        <i class="fas fa-chevron-left"></i>
                    </button>
                </a>
            </div>
            <h3>ผลการออกรางวัล</h3>
            <el-button
                    @click="exit"
                    class="lotto-drawer-close-button"
                    icon="el-icon-close"
                    circle></el-button>
        </div>
        <div class="container-PageResult">

                    <div v-if="result" class=" content-PageResult-d">
<!--                        <div class="topic-header-page topic-header-page-desktop topic-header-page-desktop-result">-->
<!--                            <h3><i  class="fas fa-trophy"></i>ผลการออกรางวัล</h3>-->
<!--                        </div>-->
                        <div class="topic-tab-result">
                            <ul>
                                <li @click="currentTab = 'yeekee'"
                                :class="{'active-tab-result': currentTab === 'yeekee'}">
                                    <span>หวยไฮเทค</span>
                                </li>
                                <li @click="currentTab = 'other'"
                                :class="{'active-tab-result': currentTab === 'other'}">
                                    <span>หวยทั้งหมด</span>
                                </li>
                            </ul>
                        </div>
                        <div class="content-PageResult">
                            <div v-show="currentTab === 'yeekee'" class="right-PageResult">
                                <div class="right-PageResult-info">
                                    <div class="content-all-live">
                                        
                                        <YeekeeGuessList 
                                        :list="yeekeeGuessList">
                                        </YeekeeGuessList>

                                        <div class="wrapper-content-all-live">

                                            <div v-if="isWaitingYeekeeResult" class="wait-for-yeekee-result">
                                                <div>
                                                    <h1>กำลังออกผลรางวัล</h1>
                                                    <h4>โปรดรอสักครู่ </h4>
                                                    <div class="spinner-border" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <YeekeeLiveResult 
                                                :round="yeekeeCurrentRound"
                                                :guessList="yeekeeGuessList"
                                                :guessTotal="yeekeeGuessTotal"
                                                @timeout="refreshYeekee">
                                                </YeekeeLiveResult>
                                            </div>

                                            <!-- <h3>ผลหวยไฮเทค {{result.yeekee.round.date}}</h3> -->
                                            <div class="wrapper-content-all-yeekee">
                                                <div @click="selectRound(yeekee)" v-for="(yeekee, i) of yeekee_list_sort" :key="i" class="card card-yeekee">
                                                    <div class="card-header">
                                                        <strong class="card-box">หวยไฮเทค</strong>
                                                        <span>รอบที่ {{yeekee.round.sub_round}}</span>
                                                    </div>
                                                    <div class="col-government-2">
                                                        <div class="box-mini-yeekee">
                                                                    <div class="txt-balance">{{(yeekee.result) ? yeekee.result.sam_tua_bon || 'XXX' : 'XXX'}}</div>
                                                                    <strong>3 ตัวบน</strong>
                                                                </div>
                                                        <div class="box-mini-yeekee">
                                                                    <div class="txt-balance">{{(yeekee.result) ? yeekee.result.song_tua_lang || 'XX' : 'XX'}}</div>
                                                                    <strong>2 ตัวล่าง</strong>
                                                                </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="resultGroup">
                                        <h3>หวยทั้งหมด</h3>
                                        <div v-for="lotto_type of LOTTO_TYPE_LIST" :key="lotto_type" class="card">
                                            <div class="card-header">
                                                <LottoTypeIcon :lotto_type="lotto_type"></LottoTypeIcon>
                                                <div class="card-header-detail">
                                                    <strong class="card-box">
                                                        {{lotto_type | lottoTypeDisplay}}
                                                    </strong>
                                                    <span v-if="result[lotto_type]"> ประจำงวด : {{result[lotto_type].round.date}}
                                            {{(result[lotto_type].round.sub_round) ? 'รอบที่ ' + result[lotto_type].round.sub_round : ''}}
                                        </span>
                                                </div>
                                            </div>
                                            <div class="colContent-bill">
                                                <div v-if="lotto_type === 'government'" >
                                                    <div class="col-government-thai">
                                                        <div class="text-center thai-top-award">
                                                            <div class="mb-2">
                                                                <strong>รางวัลที่ 1</strong>
                                                                <span>รางวัลละ 6,000,000 บาท</span>
                                                                <div class="txt-balance">{{get_result_str(lotto_type, 'first')}}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-government-group">
                                                            <div class="col-government-3">
                                                                <div class=" text-center">
                                                                    <div class="txt-balance">
                                                                        {{get_result_str(lotto_type, 'sam_tua_nha_1')}}, {{get_result_str(lotto_type, 'sam_tua_nha_2')}}
                                                                    </div>
                                                                    <strong>เลขหน้า 3 ตัว</strong>
                                                                    <span>2 รางวัลๆละ 4,000 บาท</span>
                                                                </div>
                                                                <div class=" text-center">
                                                                    <div class="txt-balance">
                                                                        {{get_result_str(lotto_type, 'sam_tua_lang_1')}}, {{get_result_str(lotto_type, 'sam_tua_lang_2')}}
                                                                    </div>
                                                                    <strong>เลขท้าย 3 ตัว</strong>
                                                                    <span>2 รางวัลๆละ 4,000 บาท</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-government-4 text-center">
                                                                <div class="txt-balance">
                                                                    {{get_result_str(lotto_type, 'song_tua_lang')}}
                                                                </div>
                                                                <strong>เลขท้าย 2 ตัว</strong>
                                                                <span>1 รางวัลๆละ 2,000 บาท</span>

                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <div class="col-government-2">
                                                        <div class="col-6 text-center">
                                                            <div class="txt-balance">{{get_result_str(lotto_type, 'sam_tua_bon')}}</div>
                                                            <strong>3 ตัวบน</strong>

                                                        </div>
                                                        <div class="col-6 text-center">
                                                            <div class="txt-balance">{{get_result_str(lotto_type, 'song_tua_lang')}}</div>
                                                            <strong>2 ตัวล่าง</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="card card-last-update">
                                        <div class="card-header">
                                            <div data-v-cdb81c00="" class="logo-yeekee"></div>
                                            <div class="card-header-detail">
                                                <strong class="card-box">
                                                    {{"yeekee" | lottoTypeDisplay}}
                                                </strong>
                                                <span v-if="result['yeekee']"> ประจำงวด : {{result['yeekee'].round.date}}
                                            {{(result['yeekee'].round.sub_round) ? 'รอบที่ ' + result['yeekee'].round.sub_round : ''}}
                                        </span>
                                            </div>
                                        </div>
                                        <div class="colContent-bill">
                                            <div class="row">
                                                <div class="col-government-2">
                                                    <div class="col-6 text-center">
                                                        <div class="border-right">
                                                            <div class="txt-balance">{{get_result_str('yeekee', 'sam_tua_bon')}}</div>
                                                            <strong>3 ตัวบน</strong>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 text-center">
                                                        <div>
                                                            <div class="txt-balance">{{get_result_str('yeekee', 'song_tua_lang')}}</div>
                                                            <strong>2 ตัวล่าง</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h3>ผลหวยไฮเทค {{result.yeekee.round.date}}</h3>
                                    <div v-for="(yeekee, i) of yeekee_list_sort" :key="i" class="card card-yeekee">
                                        <div class="card-header">
                                            <strong class="card-box">หวยไฮเทค รอบที่ </strong>
                                            <span>{{yeekee.round.sub_round}}</span>
                                        </div>
                                        <div class="p-2">
                                            <div class="row">
                                                <div class="col-government-2">
                                                    <div class="col-6 text-center">

                                                        <div class="txt-balance">{{(yeekee.result) ? yeekee.result.sam_tua_bon || 'XXX' : 'XXX'}}</div>
                                                        <strong>3 ตัวบน</strong>
                                                    </div>
                                                    <div class="col-6 text-center">

                                                        <div class="txt-balance">{{(yeekee.result) ? yeekee.result.song_tua_lang || 'XX' : 'XX'}}</div>
                                                        <strong>2 ตัวล่าง</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <div v-show="currentTab === 'other'" class="left-PageResult">
                                <!--                        <h4><i class="fas fa-award"></i> </h4>-->
                                <div v-for="lotto_type of LOTTO_TYPE_LIST" :key="lotto_type" class="card">
                                    <div class="card-header">
                                        <LottoTypeIcon :lotto_type="lotto_type"></LottoTypeIcon>
                                        <div class="card-header-detail">
                                            <strong class="card-box">
                                                {{lotto_type | lottoTypeDisplay}}
                                            </strong>
                                            <span v-if="result[lotto_type]"> ประจำงวด : {{result[lotto_type].round.date}}
                                            {{(result[lotto_type].round.sub_round) ? 'รอบที่ ' + result[lotto_type].round.sub_round : ''}}
                                        </span>
                                        </div>
                                    </div>
                                    <div class="colContent-bill">
                                        <div v-if="lotto_type === 'government'" class="row ">
                                            <div class="col-government-thai">
                                                <div class="col-12 text-center thai-top-award">
                                                    <div class="mb-2">
                                                        <strong>รางวัลที่ 1</strong>
                                                        <span>รางวัลละ 6,000,000 บาท</span>
                                                        <div class="txt-balance">{{get_result_str(lotto_type, 'first')}}</div>
                                                    </div>
                                                </div>
                                                <div class="col-government-group">
                                                    <div class="col-government-3">
                                                        <div class=" text-center">
                                                            <div class="txt-balance">
                                                                {{get_result_str(lotto_type, 'sam_tua_nha_1')}}, {{get_result_str(lotto_type, 'sam_tua_nha_2')}}
                                                            </div>
                                                            <strong>เลขหน้า 3 ตัว</strong>
                                                            <span>2 รางวัลๆละ 4,000 บาท</span>
                                                        </div>
                                                        <div class=" text-center">
                                                            <div class="txt-balance">
                                                                {{get_result_str(lotto_type, 'sam_tua_lang_1')}}, {{get_result_str(lotto_type, 'sam_tua_lang_2')}}
                                                            </div>
                                                            <strong>เลขท้าย 3 ตัว</strong>
                                                            <span>2 รางวัลๆละ 4,000 บาท</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-government-4 text-center">
                                                        <div class="txt-balance">
                                                            {{get_result_str(lotto_type, 'song_tua_lang')}}
                                                        </div>
                                                        <strong>เลขท้าย 2 ตัว</strong>
                                                        <span>1 รางวัลๆละ 2,000 บาท</span>

                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        <div v-else class="row">
                                            <div class="col-government-2">
                                                <div class="col-6 text-center">
                                                        <div class="txt-balance">{{get_result_str(lotto_type, 'sam_tua_bon')}}</div>
                                                        <strong>3 ตัวบน</strong>

                                                </div>
                                                <div class="col-6 text-center">
                                                        <div class="txt-balance">{{get_result_str(lotto_type, 'song_tua_lang')}}</div>
                                                        <strong>2 ตัวล่าง</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


        <!-- <div id="YeekeeDetailModal" class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">รายละเอียดผล หวยไฮเทค</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <YeekeeDetail :round_id="selected_round_id"/>
                    </div>
                </div>
            </div>
        </div> -->

    </div>
</template>

<script>

import axiosWrapper from '@/axiosWrapper'
import AppMixin from './mixins/App'
import YeekeeGuessList from './components/yeekee/GuessList'
import YeekeeLiveResult from './components/yeekee/LiveResult'
import waitForSecond from '@/lib/waitForSecond'
import ReconnectingWebSocket from 'reconnecting-websocket'
import LottoTypeIcon from './components/LottoTypeIcon'
import {LOTTO_TYPE} from './Constant'

export default {
    name: "Result",
    components: {
        YeekeeGuessList,
        YeekeeLiveResult,
        LottoTypeIcon,
    },
    mixins: [
        AppMixin,
    ],
    data(){
        return {
            LOTTO_TYPE_LIST: [
                // 'yeekee',
                'government',
                'hanoy',
                'lao',
                'malay',
            ],
            result: null,
            yeekee_list: [],
            yeekee_list_sort: [],
            selected_round_id: null,
            yeekeeSelectRound: null,
            yeekeeGuessList: [],
            yeekeeGuessTotal: 0,
            isWaitingYeekeeResult: false,
            currentTab: 'yeekee', //yeekee, other
            socket: null,
        }
    },
    computed: {
        yeekeeCurrentRound() {
            if(this.yeekeeSelectRound) {
                return this.yeekeeSelectRound
            } else {
                return null
            }
        },
        yeekeeCurrentRoundId() {
            if(this.yeekeeCurrentRound) {
                return this.yeekeeCurrentRound.id
            } else {
                return null
            }
        }
    },
    watch: {
        yeekeeCurrentRoundId(newVal) {
            if(newVal) {
                this.getYeekeeGuess(newVal)
            }
        }
    },
    methods: {
        is_round_open: function (status, timeout_str) {
            const now = this.$moment();
            const timeout = this.$moment(timeout_str).add(3, 'minutes');
            return (status === 'open' && now.isSameOrBefore(timeout));
        },
        get_all_lotto_type_latest: async function () {
            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LOTTO_API}/api/result/get_all_lotto_type_latest`,
            });
            if (res.status === 200) {
                this.result = res.data;
            } else {
                this.$bvToast.toast(res.data.message, {title: 'แจ้งเตือน'});
            }
        },
        get_result_str: function (lotto_type, result_type) {

            try {

                if(!this.result) {
                    return '-';
                }
                if(!this.result[lotto_type]) {
                    return '-';
                }
                if(!this.result[lotto_type][result_type]) {
                    return '-';
                }

                return this.result[lotto_type][result_type];

            } catch (err) {

                console.log('get_result_str err', err.message);
                return '-';
            }
        },
        sortYeekee(list) {

            if(list.length > 0){

                const extracted = list.map((o) => {
                    o.is_round_open = this.is_round_open(o.round.status, o.round.timeout);
                    return o;
                });
                const open_round = extracted.filter((o) => {
                    return o.is_round_open;
                });
                const close_round = extracted.filter((o) => {
                    return !o.is_round_open;
                }).reverse();

                let result = close_round.concat(open_round);

                if(open_round.length > 0){
                    const latest_opened = open_round.splice(0, 1)[0];
                    result.splice(0, 0, latest_opened);
                }
                return result;
            }else{
                return [];
            }
        },
        getYeekeeByDate: async function(){

            const res = await axiosWrapper({
                method: 'post',
                url: `${this.envDomains.VUE_APP_LOTTO_API}/api/result/get_yeekee_by_date`,
            });
            this.yeekee_list = res.data;
            this.yeekee_list_sort = this.sortYeekee(res.data)
            if(!this.yeekeeSelectRound) {
                this.yeekeeSelectRound = this.yeekee_list_sort[0].round
            }
        },
        selectRound: function(data){
            this.yeekeeSelectRound = data.round
        },
        getYeekeeGuess: async function (round_id) {

            const res = await axiosWrapper({
                method: 'post',
                url: `${this.envDomains.VUE_APP_LOTTO_API}/api/guess/get_list`,
                data: {
                    round_id: round_id
                }
            });
            this.yeekeeGuessList = res.data.list
            this.yeekeeGuessTotal = res.data.total
        },
        loadData() {
            this.get_all_lotto_type_latest();
            this.getYeekeeByDate();
        },
        async refreshYeekee() {
            this.isWaitingYeekeeResult = true
            await waitForSecond(20*1000)
            await this.getYeekeeByDate()
            await this.getYeekeeGuess(this.yeekeeCurrentRoundId)
            this.isWaitingYeekeeResult = false
        },
        messageRecieve(event){

            const message_recieve = JSON.parse(event.data)

            if(message_recieve.round_id !== this.yeekeeCurrentRoundId) {
                return
            }

            const guess = {...message_recieve}
            
            this.yeekeeGuessList.splice(0, 0, guess)
            this.yeekeeGuessList.splice(this.yeekeeGuessList.length - 1, 1)
            this.yeekeeGuessTotal = parseInt(this.yeekeeGuessTotal) + parseInt(guess.number)
        },
        subscribe(){
            if(this.socket){
                this.socket.removeEventListener('message', this.messageRecieve)
            }
            this.socket = new ReconnectingWebSocket(`${this.envDomains.VUE_APP_LOTTO_WS}/api/guess/subscribe`)
            this.socket.addEventListener('message', this.messageRecieve)
        },
    },
    created() {
        this.LOTTO_TYPE_LIST = [];
        for(const key in LOTTO_TYPE) {
            if(key === 'yeekee') {
                continue;
            }
            this.LOTTO_TYPE_LIST.push(key);
        }
    },
    async mounted() {
        this.loadData()
        this.subscribe()
        this.loadPriceTable()
        this.loadRewardLimit()
    }
}
</script>

<!--<style src="@/assets/css/lotto/reset.css"></style>-->
<!--<style src="@/assets/css/lotto/bet.css"></style>-->
<!--<style src="@/assets/css/lotto/style.css"></style>-->
<!--<style src="@/assets/css/lotto/money.css"></style>-->

<style>
    .ViewResultLotto .box-txt{display: none}
    .ViewResultLotto .box-menu-top {
        justify-content: flex-end;
    }
    /*.ViewResultLotto .box-nav-menu{display: none}*/




</style>
<style scoped>
    /*.container-PageResult{position: relative; z-index: 3}*/
    .PageResult .nav-top{
        position: absolute;
        height: 50px;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 50%;
        background-color: transparent;
        z-index: 2;
        top: 0;
    }
    .nav-top .text-right {
        margin-right: 44px;
    }
    .nav-top h3{
        /*position: absolute;*/
        font: 800 18px Athiti;
        /*top: -38px;*/
        /*left: 48px;*/
    }
    .PageResult .nav-top .col-nav{
        /*position: absolute;*/
        /*top: -50px;*/
        z-index: 3;
    }
    .nav-top .row-balance{display: none}
    .nav-top .el-button.is-circle{display: none}
    .nav-top .text-right {
        margin-right: 44px;
    }
    .card-header.card-header-bg {
        background-image: linear-gradient(135deg, #ABDCFF 10%, #0396FF 100%);
        border-bottom: 0px;
        border-radius: 15px 15px 0px 0px;
    }

    .txt-lg {
        font-size: 1.8em;
    }

    .card-radius {
        border-radius: 15px;
    }


    .card-box i, .card-box img {
        border-radius: 5px;
        padding: 5px;
        background-color: #fff;
        margin-right: 5px;
        -webkit-box-shadow: 0px 1px 5px 1px rgba(221, 221, 221, 1);
        -moz-box-shadow: 0px 1px 5px 1px rgba(221, 221, 221, 1);
        box-shadow: 0px 1px 5px 1px rgba(221, 221, 221, 1);
    }

    .card-box img {
        width: 40px;
    }
</style>